import qs from 'qs'
import { error, success } from '@core/utils/utils'

export default {
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true, // 此页面是否在创建时，调用查询数据列表接口？
        activatedIsNeed: false, // 此页面是否在激活（进入）时，调用查询数据列表接口？
        getDataListURL: '', // 数据列表接口，API地址
        getDataListIsPage: false, // 数据列表接口，是否需要分页？
        deleteURL: '', // 删除接口，API地址
        deleteIsBatch: false, // 删除接口，是否需要批量？
        deleteIsBatchKey: 'id', // 删除接口，批量状态下由那个key进行标记操作？比如：pid，uid...
        exportURL: '', // 导出接口，API地址
      },
      searchStr: '', // 搜索
      order: '', // 排序，asc／desc
      orderField: '', // 排序，字段
      page: 1, // 当前页码
      pageSize: 10, // 每页数
      total: 0, // 总条数
      dataListLoading: false, // 数据列表，loading状态
      dataListSelections: [], // 数据列表，多选项
      dataForm: {}, // 查询条件
      dataList: [], // 数据列表
      editVisible: false, // 编辑（新增或修改）弹窗的状态
    }
  },
  created() {
    if (this.mixinViewModuleOptions.createdIsNeed) {
      this.query()
    }
  },
  activated() {
    if (this.mixinViewModuleOptions.activatedIsNeed) {
      this.query()
    }
  },
  methods: {
    query() {
      this.dataListLoading = true
      this.$http.post(this.mixinViewModuleOptions.getDataListURL, {
        order: this.order,
        searchStr: this.searchStr,
        orderField: this.orderField,
        page: this.page,
        pageSize: this.pageSize,
        ...this.dataForm,
      }).then(({ data: res }) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          this.dataList = []
          this.total = 0
          this.$message.error(res.msg)
        }
        this.dataList = this.mixinViewModuleOptions.getDataListIsPage ? res.data.list : res.data
        this.total = this.mixinViewModuleOptions.getDataListIsPage ? res.data.total : 0
      }).catch(() => {
        this.dataListLoading = false
      })
    },
    getDataList() {
      this.page = 1
      this.query()
    },
    addOrUpdateHandle(type, row) {
      this.$refs.editDialog.dialogVisible = true
      if (this.$refs.editDialog.saveType) {
        this.$refs.editDialog.saveType = type
      }
      if (type === 2) {
        this.$refs.editDialog.form = { ...row }
      }
    },
    deleteHandle(id, otherParams) {
      if (this.mixinViewModuleOptions.deleteIsBatch && !id && this.dataListSelections.length <= 0) {
        return error('请选择删除项')
      }
      this.$confirm('确认删除吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          const url = this.mixinViewModuleOptions.deleteURL
          const param = this.mixinViewModuleOptions.deleteIsBatch
            ? ({ ids: id ? [id] : this.dataListSelections.map(item => item[this.mixinViewModuleOptions.deleteIsBatchKey]), ...otherParams })
            : {}
          this.$http.post(
            url, param,
          ).then(({ data: res }) => {
            if (res.code === 0) {
              success(res.msg)
              this.handleQuery()
            } else {
              error(res.msg)
            }
          }).catch(() => {
          })
        }).catch(() => {
        })
      return true
    },
    // 导出
    exportHandle() {
      const params = qs.stringify({
        token: localStorage.getItem(this.jwtConfig.storageTokenKeyName),
        ...this.dataForm,
      })
      window.location.href = `${window.SITE_CONFIG.apiURL}${this.mixinViewModuleOptions.exportURL}?${params}`
    },
    dataListSortChangeHandle(data) {
      if (!data.order || !data.prop) {
        this.order = ''
        this.orderField = ''
        return false
      }
      this.order = data.order.replace(/ending$/, '')
      this.orderField = data.prop.replace(/([A-Z])/g, '_$1').toLowerCase()
      this.query()
    },
    dataListSelectionChangeHandle(val) {
      this.dataListSelections = val
    },
    handleQuery() {
      this.page = 1
      this.pageSize = 10
      this.query()
    },
  },
}
